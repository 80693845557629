<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Virksomheder</div>
          <!--<span class="grey&#45;&#45;text">Oversigt over din daglige præstation de seneste 30 dage</span>-->
        </div>
        <v-spacer></v-spacer>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              color="primary"
              @click="showFiltersDialog = true"
              :v-on="on"
            >
              <v-badge color="blue-grey lighten-2">
                <span slot="badge" v-if="filtersCount > 0">{{
                  filtersCount
                }}</span>
                <v-icon small>fas fa-filter</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>Filtrér</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <business-data-table
          :items="businesses"
          :loading="loading"
          :total="total"
          :page="page"
          @clicked="
            $router.push({ name: 'business', params: { id: $event.uuid } })
          "
          @changePage="getBusinessesData($event)"
        ></business-data-table>
      </v-card-text>
    </v-card>

    <business-filters-dialog
      v-model="showFiltersDialog"
      :filters="filters"
      @save="(filters = $event), (page = 1)"
    ></business-filters-dialog>
  </div>
</template>

<script>
import search_filters_url from "@/helpers/mixins/search_filters_url.js";
import BusinessDataTable from "../data-tables/BusinessDataTable";
import BusinessFiltersDialog from "../dialogs/BusinessFiltersDialog";
import { mapActions } from "vuex";

export default {
  components: { BusinessFiltersDialog, BusinessDataTable },
  mixins: [search_filters_url],
  data: () => ({
    loading: true,
    businesses: [],
    showFiltersDialog: false,
    filters: {},
    total: null,
    page: 1
  }),
  computed: {
    filtersCount() {
      let filters = this.cloneDeep(this.filters);

      let count = Object.keys(filters).length;

      // If search key is set but has no value. Then subtract 1 from count
      if ("search" in filters && !filters.search) {
        count--;
      }

      return count;
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.businesses = [];
        this.setUrlSearchFilters(this.filters);
        this.getBusinessesData();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", ["getBusinesses"]),
    getBusinessesData(page = 1) {
      this.businesses = [];
      this.page = page;
      this.loading = true;
      const params = {
        ...this.filters,
        page: this.page,
        limit: 15
      };
      this.getBusinesses(params).then(businesses => {
        this.businesses = businesses.response;
        this.total = businesses.lastPage;
        this.loading = false;
      });
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();
    if (Object.keys(this.filters).length === 0) {
      this.filters.has_subscription = true;
    }
  }
};
</script>
