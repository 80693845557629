<template>
<div class="businesess-table-wrapper">
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :items-per-page="items.length"
    hide-default-footer
  >
    <v-progress-linear
      slot="progress"
      color="primary"
      indeterminate
    ></v-progress-linear>
    <template v-slot:body="{ items }">
      <tbody>
        <tr
          v-for="item in items"
          :key="item.uuid"
          class="cursor-pointer"
          @click="clickAction(item)"
        >
          <td>
            <router-link :to="{ name: 'business', params: { id: item.uuid } }">
              {{ item.display_name }}
            </router-link>
          </td>
          <td>{{ item.user.first_name }} {{ item.user.last_name }}</td>
          <td>{{ item.user.email }}</td>
          <td><a :href="`tel: ${item.phone }`">{{ item.phone }}</a></td>

          <td>
            <v-icon v-if="item.pixel_approved_at" color="success" small
              >fal fa-check</v-icon
            >
            <v-icon v-else color="error" small>fal fa-times</v-icon>
          </td>
          <td>
            <v-icon
              v-if="
                item.user.active_subscription &&
                  !item.user.active_subscription.ends_at
              "
              color="success"
              small
              >fal fa-check</v-icon
            >

              <v-tooltip
                v-else-if="
                  item.user.active_subscription &&
                    item.user.active_subscription.ends_at
                "
                top
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="warning" small>fal fa-clock</v-icon>
                </template>
                <span
                  >Opsagt - Udløb d.
                  {{
                    item.user.active_subscription.ends_at
                      | utc_to_local_datetime
                  }}</span
                >
              </v-tooltip>

              <v-icon v-else color="error" small>fal fa-times</v-icon>
            </td>
            <td class="word-no-wrap">
              <div v-if="item.agency" v-text="item.agency.name"></div>
              <v-icon v-else color="error" small>fal fa-times</v-icon>
            </td>
            <td class="word-no-wrap">
              {{ item.webshop_system }}
            </td>
            <td class="word-no-wrap">
              {{ item.created_at | utc_to_local_datetime }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <v-pagination
      v-model="currPage"
      class="my-2"
      :length="total"
      :total-visible="7"
      @input="handlePageChange"
    ></v-pagination>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";

export default {
  filters: {
    local_numbers,
    utc_to_local_datetime
  },
  props: {
    page: {
      type: Number
    },
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    total: {
      type: Number
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    currPage: 1,
    headers: [
      { text: "Virksomhed", value: "display_name", sortable: false },
      { text: "Kontaktperson", value: "user.first_name", sortable: false },
      { text: "Email", value: "user.email", sortable: false },
      { text: "Telefon", value: "phone", sortable: false  },
      { text: "Godkendt pixel", value: "pixel_approved_at", sortable: false },
      { text: "Abonnement", value: "user.subscriptions", sortable: false },
      { text: "Agency", value: "agency.name", sortable: false },
      { text: "Webshop system", value: "webshop_system", sortable: false },
      { text: "Oprettelse", value: "user.created_at", sortable: false }
    ]
  }),
  methods: {
    handlePageChange(value) {
      this.$emit("changePage", value);
    },
    clickAction(business) {
      this.$emit("clicked", business);
    }
  },
  watch: {
    items() {
      this.currPage = this.page;
    }
  }
};
</script>
