<template>
  <v-dialog v-model="showing" width="100%" max-width="500px">
    <v-form @submit.prevent="save()">
      <v-card>
        <v-card-title class="headline" primary-title>
          Virksomheds filtréring
        </v-card-title>
        <v-card-text>
          <v-container fluid grid-list-xl class="p-0">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="iFilters.search"
                  append-icon="search"
                  label="Søgeord"
                  clearable
                ></v-text-field>

                <v-select
                  label="Pixel"
                  v-model="iFilters.has_pixel_approved"
                  :items="hasPixelOptions"
                  clearable
                ></v-select>

                <v-select
                  label="Abonnement"
                  v-model="iFilters.has_subscription"
                  :items="hasSubscriptionOptions"
                  clearable
                ></v-select>

                <v-select
                  label="Opsagt abonnement"
                  v-model="iFilters.has_subscription_canceled"
                  :items="hasCanceledSubscriptionOptions"
                  clearable
                ></v-select>

                <v-select
                  label="Managed"
                  v-model="iFilters.is_managed"
                  :items="isManagedOptions"
                  clearable
                ></v-select>
                  <v-select
                  label="Webshop System"
                  v-model="iFilters.webshop_system"
                  :items="shopSystemList"
                  clearable
                ></v-select>
               
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary">
            Filtrér
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: {
    value: {
      required: true
    },
    filters: {
      required: true
    }
  },
  data: () => ({
    iFilters: {},
    hasPixelOptions: [
      { text: "Har godkendt pixel", value: true },
      { text: "Har ikke godkendt pixel", value: false }
    ],
    hasSubscriptionOptions: [
      { text: "Kun virksomheder med abonnement", value: true },
      { text: "Kun virksomheder uden abonnement", value: false }
    ],
    hasCanceledSubscriptionOptions: [
      { text: "Kun opsagt", value: true },
      { text: "Ikke opsagt", value: false }
    ],
    isManagedOptions: [
      { text: "Er managed", value: true },
      { text: "Er IKKE managed", value: false }
    ],
     shopSystemList: [
      "Dandomain",
      "Magento",
      "Scannet",
      "Shopify",
      "Smartweb",
      "Wannafind",
      "Woocommerce",
      "Andet"
    ]
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  watch: {
    filters: {
      handler() {
        this.iFilters = this.cloneDeep(this.filters);
      },
      deep: true
    }
  },
  methods: {
    save() {
      this.$emit("save", this.cloneDeep(this.iFilters));
      this.showing = false;
    }
  },
  created() {
    this.iFilters = this.cloneDeep(this.filters);
  }
};
</script>
