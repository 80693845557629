var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"businesess-table-wrapper"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":_vm.items.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.uuid,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.clickAction(item)}}},[_c('td',[_c('router-link',{attrs:{"to":{ name: 'business', params: { id: item.uuid } }}},[_vm._v(" "+_vm._s(item.display_name)+" ")])],1),_c('td',[_vm._v(_vm._s(item.user.first_name)+" "+_vm._s(item.user.last_name))]),_c('td',[_vm._v(_vm._s(item.user.email))]),_c('td',[_c('a',{attrs:{"href":("tel: " + (item.phone))}},[_vm._v(_vm._s(item.phone))])]),_c('td',[(item.pixel_approved_at)?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("fal fa-check")]):_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("fal fa-times")])],1),_c('td',[(
                item.user.active_subscription &&
                  !item.user.active_subscription.ends_at
              )?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v("fal fa-check")]):(
                  item.user.active_subscription &&
                    item.user.active_subscription.ends_at
                )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"warning","small":""}},on),[_vm._v("fal fa-clock")])]}}],null,true)},[_c('span',[_vm._v("Opsagt - Udløb d. "+_vm._s(_vm._f("utc_to_local_datetime")(item.user.active_subscription.ends_at)))])]):_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("fal fa-times")])],1),_c('td',{staticClass:"word-no-wrap"},[(item.agency)?_c('div',{domProps:{"textContent":_vm._s(item.agency.name)}}):_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("fal fa-times")])],1),_c('td',{staticClass:"word-no-wrap"},[_vm._v(" "+_vm._s(item.webshop_system)+" ")]),_c('td',{staticClass:"word-no-wrap"},[_vm._v(" "+_vm._s(_vm._f("utc_to_local_datetime")(item.created_at))+" ")])])}),0)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1),_c('v-pagination',{staticClass:"my-2",attrs:{"length":_vm.total,"total-visible":7},on:{"input":_vm.handlePageChange},model:{value:(_vm.currPage),callback:function ($$v) {_vm.currPage=$$v},expression:"currPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }